import React from 'react'
import './Clients.css'

const Clients = () => {
     return (
          <section>
               <img src="/assets/images/clients.png" className='w-100' alt="" />
          </section>
     )
}

export default Clients